.page-content {
    // margin-left: 280px;
    // todo: fix

    &.unauthenticated {
        margin-left: 0px;  
    }
}

.app-shell {
    height: 100%;
}