body {
    margin: 0;
    font-family: Roboto
}
button.react-multiple-carousel__arrow--right{
    right: 0px;
    border-radius: 0;
    height: 100%;
}
button.react-multiple-carousel__arrow--left{
    left: 0px;
    border-radius: 0;
    height: 100%;
}
.MuiDrawer-paper {
    width: 20%;
}